import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useNavigate, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useCSVReader } from 'react-papaparse';

import { FormData } from '../helpers/FormData';
import { _customPriceAdd, _customPriceEdit, _customPriceGetById, _usersListGet } from '../services/apiConnections.service';

export const dateToLocalISO = (date) => {
    let timeZoneOffset = (new Date(date)).getTimezoneOffset() * 60000;
    let localISOTime = (new Date(date - timeZoneOffset)).toISOString();
    return localISOTime;
};

export const dateToUTC = (date) => {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()).toISOString();
};

export const UTCToLocalDate = (date) => {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
};

export const CustomPriceUpsert = (props) => {

    const { CSVReader } = useCSVReader();

    const [error, setError] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [formValues, handleFormValueChange, setFormValues] = FormData({
        email: '',
        start_at: '',
        label: '',
        prices: '',
        description: ''
    });
    const [effectiveDate, setEffectiveDate] = useState(new Date());
    const [formErrors, setFormErrors] = useState({});
    const [emails, setEmails] = useState([]);

    const [prices, setPrices] = useState([]);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [pricesUploadError, setPricesUploadError] = useState(null);
    const [pricesUploadSuccess, setPricesUploadSuccess] = useState(null);

    let navigate = useNavigate();
    let params = useParams();

    useEffect(() => {
        if (!emails.length) {
            handleEmails();
        }
    }, [emails]);

    useEffect(() => {
        if (props.edit) {
            handlePriceGet();
        }
    }, [props]);

    useEffect(() => {
        if (uploadedFile) handleFormValueChange('label', uploadedFile.name);
    }, [uploadedFile]);

    const handleEmails = async () => {
        let result = await _usersListGet(!props.edit);
        if (result.error) {
            console.log("error");
            setError(result.error);
        } else {
            let arr = result.users.map((e) => {
                return {
                    label: e.email,
                    value: e.id
                }
            });
            setEmails(arr);
        }
    };

    const handlePriceGet = async () => {
        let result = await _customPriceGetById(params.id);
        if (result.error) {
            console.log("--error: ", result.error);
        } else {
            setFormValues({
                email: {
                    label: result.user_email,
                    value: result.user_id
                },
                start_at: result.start_at,
                prices: result.prices,
                description: result.details,
                label: result.label
            });
            setEffectiveDate(UTCToLocalDate(new Date(result.start_at)));
            setPrices(result.prices);
            setUploadedFile({ name: result.label });
        }
    };

    const handleUpsert = async (event) => {
        event.preventDefault();
        let data = {
            details: formValues.description,
            label: formValues.label,
            prices: prices,
            start_at: new Date(effectiveDate),
            user_id: formValues.email.value
        }
        let result;

        if (!props.edit)
            result = await _customPriceAdd(data);
        else
            result = await _customPriceEdit(data, params.id);

        if (result.error) {
            setError(result.error);
        } else {
            console.log("success");
            navigate('/custom-prices');
        }
    };

    const handlePricesUploads = (uploadedPrices) => {
        setPricesUploadError(null);
        setPricesUploadSuccess(null);
        let result = [];
        let data = uploadedPrices.data;
        let keys = ['name', 'direction', 'cost'];
        // parsing
        if (data[0][0].toLowerCase() != 'country' || data[0][1].toLowerCase() != 'destination' || data[0][2].toLowerCase() != 'price') {
            setPricesUploadError("Invalid file content");
            return;
        }
        for (let line = 1; line < data.length; line++) {
            let price = {};
            for (let cell = 0; cell < data[line].length; cell++) {
                if (data[line][cell].length) {
                    price[keys[cell]] = data[line][cell];
                }
            }
            if (Object.keys(price).length !== 0) {
                result.push(price);
            }
        }
        // validation
        if (result.length) {
            setPricesUploadError(null);
            setPricesUploadSuccess(`Your price list file meets the requirements`);
        } else {
            setPricesUploadError("Invalid file content");
            setPricesUploadSuccess(null);
        }
        setPrices(result);
    };

    const handleValidation = (target) => {
        switch (target.name) {
            case 'description':
                if (target.value.length > 255) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        description: "Maximum number of characters - 255"
                    }));
                } else {
                    let copy = { ...formErrors };
                    delete copy['description'];
                    setFormErrors(copy);
                }
                break;

            default: break;
        }
    };

    const formIsValid = () => {
        return !Object.keys(formErrors).length && formValues.email && prices.length;
    };

    return (
        <div className='content'>
            <div>
                <div className='pageTitle'>
                    <span>{!props.edit ? 'Upload Price' : 'Edit Price'}</span>
                </div>
                {error && <p className='msgBlock errorMsg'>{error}</p>}
                <div className='pageContent'>
                    <div className="mb-3">
                        <label
                            className='inputLabel requiredInput'
                            htmlFor='email'>Email</label>
                        <div className='inputWrapper'>
                            <Select
                                id='email'
                                name='email'
                                className='customSelect'
                                classNamePrefix='select'
                                options={emails}
                                placeholder='Select Email'
                                value={formValues.email}
                                isDisabled={props.edit}
                                onChange={(selectedOption) => handleFormValueChange(
                                    'email',
                                    selectedOption
                                )} />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className='inputLabel requiredInput'>Effective Date</label>
                        <div className='inputWrapper'>
                            <DatePicker
                                selected={effectiveDate}
                                onChange={(date) => {
                                    setEffectiveDate(new Date(date));
                                }}
                                selectsStart
                                dateFormat="MMMM d, yyyy"
                                minDate={new Date()}
                                className='formInput datePicker'
                            />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label
                            className='inputLabel requiredInput'
                            htmlFor='prices'>Upload Prices</label>
                        <CSVReader
                            onUploadAccepted={(results, file) => {
                                handlePricesUploads(results);
                                setUploadedFile(file);
                            }}>
                            {
                                ({
                                    getRootProps,
                                    acceptedFile,
                                    ProgressBar,
                                    getRemoveFileProps
                                }) => (
                                    <div>
                                        <div className='fileUploadWrapper fileUploadForm'>
                                            <button {...getRootProps()}
                                                type='button'
                                                className="browseFileBtn btn"
                                                onClick={(event) => {
                                                    getRootProps().onClick(event)
                                                }}>Browse File</button>
                                            <div className='fileName'>
                                                <span>{(acceptedFile && acceptedFile.name) || (uploadedFile && uploadedFile.name)}</span>
                                            </div>
                                            {(acceptedFile || uploadedFile) &&
                                                <button {...getRemoveFileProps()}
                                                    className='removeFileBtn btn'
                                                    onClick={(event) => {
                                                        getRemoveFileProps().onClick(event);
                                                        setPrices([]);
                                                        setUploadedFile(null);
                                                        setPricesUploadError(null);
                                                        setPricesUploadSuccess(null);
                                                    }}>Remove</button>
                                            }
                                        </div>
                                        <ProgressBar />
                                    </div>
                                )
                            }
                        </CSVReader>
                        {pricesUploadError && <p className='msgBlock errorMsg' style={{ marginTop: 5 + 'px' }}>{pricesUploadError}</p>}
                        {pricesUploadSuccess && <p className='msgBlock successMsg' style={{ marginTop: 5 + 'px' }}>{pricesUploadSuccess}</p>}
                    </div>
                    <div className="mb-3">
                        <label
                            className='inputLabel'
                            htmlFor='description'>Description</label>
                        <div className='inputWrapper'>
                            <textarea
                                name='description'
                                id='description'
                                className={`formInput formTextArea ${formErrors.description ? 'invalidInput' : ''}`}
                                placeholder='Description'
                                value={formValues.description}
                                onChange={(event) => {
                                    handleFormValueChange(
                                        'description',
                                        event.target.value
                                    )
                                }}
                                onKeyUp={(event) => { handleValidation(event.target) }}></textarea>
                            <span className='fieldError'>{formErrors.description}</span>
                        </div>
                    </div>
                    <div className='buttonBlock'>
                        <button
                            style={{ 'marginRight': '10px' }}
                            onClick={() => { navigate('/custom-prices') }}
                            className={`mainBtn btn`}>Cancel</button>
                        <button
                            onClick={handleUpsert}
                            className={`mainBtn btn ${formIsValid() ? '' : 'disabledBtn'}`}
                            disabled={!formIsValid()}>{props.edit ? 'Save' : 'Add'}</button>
                    </div>
                </div>
            </div>
        </div >
    );
};