import { dateToUTC } from "../pages/PaymentUpsert.page";
import { HandleError } from "./errorHandler.service";

const url = process.env.REACT_APP_ADMIN_API_URL;

export const _usersGet = async () => {
    return await _get(`/users`);
};

export const _pricesGet = async (search) => {
    return await _get(`/prices${search ? `?search=${search}` : ''}`);
};

export const _pricesUpload = async (data) => {
    return await _fetch(data, `/prices`);
};

export const _tariffsGet = async () => {
    return await _get(`/tariff-packages`);
};

export const _tariffPlanAdd = async (data) => {
    const dataToSend = {
        "name": data.name,
        "allowed_directions": data.destination.filter((e) => { return e }),
        "denied_destinations": data.deny_destination.filter((e) => { return e }),
        "start_date": data.start_date,
        "end_date": data.end_date,
        "cost": data.price.toString(),
        "validity_period": parseInt(data.validity_time),
        "sms_limit": parseInt(data.sms_limit),
        "description": data.description
    };
    return await _fetch(dataToSend, `/tariff-packages`);
};

export const _tariffPlanEdit = async (data, id) => {

};

export const _tariffPlanDelete = async (id) => {
    return await _delete(`/tariff-packages/${id}`);
};

export const _sendersGet = async (search) => {
    return await _get(`/senders${search ? `?search=${search}` : ''}`);
};

export const _senderGetById = async (id) => {
    return await _get(`/senders/${id}`);
};

export const _senderUpsert = async (data, id = '') => {
    const dataToSend = {
        "number": data.name
    };
    return await _fetch(dataToSend, `/senders${id ? `/${id}` : ''}`, id);
};

export const _senderDelete = async (id) => {
    return await _delete(`/senders/${id}`);
};

export const _paymentsGet = async (search) => {
    return await _get(`/payments${search ? `?${search}` : ''}`);
};

export const _paymentGetById = async (id) => {
    return await _get(`/payments/${id}`);
}

export const _paymentAdd = async (data) => {
    const dataToSend = {
        "user_id": data.email.value,
        "payment_date": data.payment_date,
        "amount": data.paid_amount.toString(),
        "details": data.description
    };
    return await _fetch(dataToSend, `/payments`);
};

export const _paymentEdit = async (data, id) => {
    const dataToSend = {
        "user_id": data.email.value,
        "payment_date": dateToUTC(data.payment_date),
        "amount": data.paid_amount.toString(),
        "details": data.description
    };
    return await _fetch(dataToSend, `/payments/${id}`, true);
};

export const _paymentDelete = async (id) => {
    return await _delete(`/payments/${id}`);
};

export const _paymentHistoryGet = async (id) => {
    return await _get(`/payments/${id}/history`);
};

export const _tariffPlansGet = async (search) => {
    return await _get(`/tariff-package-gifts${search ? `?${search}` : ''}`);
};

export const _tariffPlanCancel = async (id) => {
    return await _delete(`/tariff-package-gifts/${id}`);
};

export const _tariffPlanAssign = async (data) => {
    const dataToSend = {
        "user_id": data.email.value,
        "tariff_package_id": data.tariff_package.value,
        "details": data.description
    };
    return await _fetch(dataToSend, `/tariff-package-gifts`);
};

export const _tariffPackagesListGet = async () => {
    return await _get(`/tariff-packages-short`);
};

export const _usersListGet = async (CustomPriceCreating = false) => {
    return await _get(`/users-short${CustomPriceCreating ? '?exclude_with_prices=true' : ''}`);
};

export const _customPricesGet = async (search) => {
    return await _get(`/prices/custom${search ? `?${search}` : ''}`);
};

export const _customPriceAdd = async (data) => {
    return await _fetch(data, `/prices/custom`);
};

export const _customPriceGetById = async (id, search = '') => {
    return await _get(`/prices/custom/${id}${search ? `?search=${search}` : ''}`);
};

export const _customPriceEdit = async (data, id) => {
    return await _fetch(data, `/prices/custom/${id}`, true);
};

export const _customPriceDelete = async (id) => {
    return await _delete(`/prices/custom/${id}`);
};

export const _customPriceHistoryGet = async (id) => {
    return _get(`/prices/custom/${id}/history`);
};

const _get = async (route) => {
    try {
        const response = await fetch(url + route, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (!response.ok) HandleError(response);
        const json = await response.json();
        return json;
    } catch (error) {
        console.log(`--${route} error: ${error}`);
    }
};

const _fetch = async (data = {}, route = '', put = false) => {
    try {
        const response = await fetch(url + route, {
            method: put ? 'PUT' : 'POST',
            body: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        });
        const json = await response.json();
        if (!response.ok) HandleError(response);
        return json;
    } catch (error) {
        console.log(`--${route} error: ${error}`);
    }
};

const _delete = async (route) => {
    const response = await fetch(url + route, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        }
    });
    if (!response.ok) HandleError(response);
};
